import React from 'react'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import { PageContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import { graphql } from 'gatsby'
import FooterCTA from '@talentinc/gatsby-theme-ecom/components/FooterCTA/FooterCTA'
import LandingPageV3Template, { Section } from '../../templates/landingPageV3'
import pageData from '../../data/resume-review.json'
import TestimonialsV2 from '@talentinc/gatsby-theme-ecom/components/TestimonialsV2/TestimonialsV2'
import {
  ResumeReviewATS,
  ResumeReviewFAQ,
  ResumeReviewHero,
  ResumeReviewWhatToExpect,
  ResumeReviewHowItWorks,
} from '@talentinc/gatsby-theme-ecom/components/ResumeReview'

type Props = {
  pageContext: PageContextType
}

export default function ResumeReview(props: Props) {
  return (
    <LandingPageV3Template pageData={pageData} pageContext={props.pageContext}>
      <ResumeReviewHero />

      <Section>
        <ResumeReviewHowItWorks />
      </Section>

      <Section innerPadded>
        <TestimonialsV2 />
      </Section>

      <Section>
        <ResumeReviewWhatToExpect />
      </Section>

      <Section innerPadded>
        <ResumeReviewATS />
      </Section>

      <Section innerPadded>
        <ResumeReviewFAQ />
      </Section>

      <FooterCTA />
      <Footer useSimulatedPageLoad={false} />
    </LandingPageV3Template>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
