import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import IntervalSlider from '@talentinc/gatsby-theme-ecom/components/IntervalSlider/IntervalSlider'
import { useResumeReviewStyles } from './styles'

const sliderItems = [
  {
    id: 1,
    title: 'Style & organization',
    description:
      "Check if your resume is neat and clearly organized. We'll assess if the format effectively highlights the impact of your achievements, and  if you have included a professional summary to introduce yourself as a candidate.",
    img: 'https://images.ctfassets.net/7thvzrs93dvf/21Xl3YvM6F7J4XA7eWWVlK/7d0d8542f52e282c888c1ef15f8f74b0/style-and-organization_2x.webp',
  },
  {
    id: 2,
    title: 'Writing & mechanics',
    description:
      "Ensure the language of your resume positions you as a professional who will add value to an organization. We'll check if the writing is clear, easy to understand, and free of mistakes. As wel as making sure your grammatical tense, tone, and punctuation are consistent throughout.",
    img: 'https://images.ctfassets.net/7thvzrs93dvf/2t1s5wDH2w894QFVQsscCU/47360d270b0093a07e180aa56bbda034/writing-and-mechanics_2x.webp',
  },
  {
    id: 3,
    title: 'ATS analysis',
    description:
      "Our free review includes an ATS analysis to determine if the Applicant Tracking System can read your resume. See how the HR algorithms interpret your experience, skills, and industry expertise, and find out if you're being scanned as a best fit for your target role.",
    img: 'https://images.ctfassets.net/7thvzrs93dvf/1KLBUS0Sblet6eS8vY4cA6/b92ff386b80b51a66f36070451510824/ats-analysis_2x.webp',
  },
]

export default function ResumeReviewWhatToExpect() {
  const { classes } = useStyles()
  const { classes: sharedClasses } = useResumeReviewStyles()

  return (
    <div className={classes.sectionWhatToExpect}>
      <Typography align="center" variant="h2" className={sharedClasses.title}>
        What to expect from your review
      </Typography>
      <Typography
        align="center"
        variant="body1"
        className={sharedClasses.subtitle}
      >
        We review your resume comprehensively so you get all the information you
        need. Feedback topics include:
      </Typography>
      <IntervalSlider
        tabTimeOutInSecond={10}
        items={sliderItems}
        classes={{
          step: classes.sliderItemClass,
          activeStep: classes.sliderActiveItemClass,
        }}
      />
    </div>
  )
}

export const useStyles = makeStyles()((theme) => ({
  sectionWhatToExpect: {
    backgroundColor: theme.colors.neutral[5],
    paddingBlock: theme.spacing(6),
    paddingInline: 109,
    borderRadius: 40,
    [theme.breakpoints.down('tablet')]: {
      paddingInline: 54,
    },
  },
  sliderItemClass: {
    background: theme.colors.neutral[10],
  },
  sliderActiveItemClass: {
    background: theme.colors.blue[250],
  },
}))
