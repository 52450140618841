import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useResumeReviewStyles } from './styles'

const howResumeReviewWorksData = [
  {
    title: 'Upload your resume',
    subtitle: 'Show us what you’re working with. It’s an easy drag and drop',
    image:
      'https://images.ctfassets.net/7thvzrs93dvf/4Ufh8zLHS9zpCjGnLOSnXS/86dbf19434550fbd744e57396c758da0/upload_your_resume.webp',
  },
  {
    title: 'Let the magic happen',
    subtitle: 'We will evaluate and run an ATS analysis on your resume',
    image:
      'https://images.ctfassets.net/7thvzrs93dvf/3JkmyiUM3Pj7H4LXFBVhVb/bc8a818bd76db59cdda8bd140dc2ae38/let_the_magic_happen.webp',
  },
  {
    title: 'View your results',
    subtitle:
      'Find out what you’re doing right and what you could improve to achieve your best resume',
    image:
      'https://images.ctfassets.net/7thvzrs93dvf/4MBBFHKVftYJh90aWtEH9x/01f2e996988af34d786bd745d3896dc5/view_your_results.webp',
  },
]

export default function ResumeReviewHowItWorks() {
  const { classes } = useStyles()
  const { classes: sharedClasses } = useResumeReviewStyles()

  return (
    <>
      <Typography align="center" variant="h2" className={sharedClasses.title}>
        How our resume review works
      </Typography>
      <Typography
        align="center"
        variant="body1"
        className={sharedClasses.subtitle}
      >
        We'll provide personalized and actionable feedback in less than 48
        hours.
      </Typography>
      <div className={classes.itemsWrapper}>
        {howResumeReviewWorksData.map(({ title, subtitle, image }, index) => {
          return (
            <div key={index} className={classes.itemWrapper}>
              <div className={classes.itemTextWrapper}>
                <Typography
                  align="center"
                  variant="h3"
                  className={classes.itemTitle}
                >
                  {title}
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  className={classes.itemSubtitle}
                >
                  {subtitle}
                </Typography>
              </div>

              <img src={image} alt={title} className={classes.itemImage} />
            </div>
          )
        })}
      </div>
    </>
  )
}

export const useStyles = makeStyles()((theme) => ({
  itemsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '32px',
    [theme.breakpoints.down(1024)]: {
      gap: '24px',
    },
    [theme.breakpoints.down('tablet')]: {
      gap: '16px',
      gridTemplateColumns: '1fr',
    },
  },
  itemWrapper: {
    backgroundColor: theme.colors.neutral[5],
    borderRadius: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemTextWrapper: {
    padding: theme.spacing(4, 6),
    flex: 1,
    flexBasis: 0,
    [theme.breakpoints.down(1024)]: {
      padding: theme.spacing(3, 2),
    },
  },
  itemTitle: {
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: 23,
    lineHeight: '28px',
    color: theme.colors.neutral[90],
    marginBottom: 8,
  },
  itemSubtitle: {
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    color: theme.colors.neutral[60],
  },
  itemImage: {
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('tablet')]: {
      maxWidth: 360,
    },
  },
}))
